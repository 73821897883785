import React, { Component } from 'react'
import Config from "../config.json";
import { Link, Redirect } from 'react-router-dom'
import '../static/dashboard.css'
import logo2 from '../static/ZinEdu_LOGO.svg'
import '../static/sch.css'
import Header from './Header';
import { EXCLUDED_STUDIO_IDS } from '../utils/constants';
class Scheduler extends Component {
  constructor () {
    super()
    const token = localStorage.getItem('token')
    let login_status = true
    if (token === null) {
      login_status = false
    }
    this.state = {
      login_status,
      token,
      isLoaded: false,
      start_time: null,
      duration: null,
      studios_available: null,
      batch_available: [],
      teacher_available: [],
      subject_available: [],
      studio: null,
      teacher: null,
      batch: null,
      topic: null,
      subject: null,
      desc:null,
      teacher_text: 'Select Teacher',
      subject_text: 'Select Subject',
      batch_text: 'Select Batch',
      teacher1:null,
      teacher_text1: 'Select Another Teacher (optional)'
    }

    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.onSubmitHandler = this.onSubmitHandler.bind(this)
  }

  onChangeHandler (e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  select_studio (id) {
    console.log(id)
    this.setState({
      studio: id
    })

    const data = { topic: this.state.topic, studio: id, start_time: this.state.start_time, duration: this.state.duration, teacher: this.state.teacher, batch: this.state.batch };

    (async () => {
		  const rawResponse = await fetch(Config.SERVER_URL +'/post-webinar/?topic=' + this.state.topic + '&studio=' + id + '&start_time=' + this.state.start_time + '&duration=' + this.state.duration + '&teacher=' + this.state.teacher + '&batch=' + this.state.batch + '&subject=' + this.state.subject + '&desc=' + this.state.desc, {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		    // body: JSON.stringify(data)
		  })
		  const content = await rawResponse.json()

		  console.log(content)
    })()

    if(this.state.teacher1!=null){
          (async () => {
            const rawResponse = await fetch(Config.SERVER_URL +'/post-webinar/?topic=' + this.state.topic + '&studio=' + id + '&start_time=' + this.state.start_time + '&duration=' + this.state.duration + '&teacher=' + this.state.teacher1 + '&batch=' + this.state.batch + '&subject=' + this.state.subject + '&desc=' + this.state.desc, {
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Token ' + this.state.token
              }
              // body: JSON.stringify(data)
            })
            const content = await rawResponse.json()

            console.log(content)
          })()
    }

    this.props.history.push('/')
  }

  select_batch (id, batch_name) {
    console.log(id)
    this.setState({
      batch: id,
      batch_text: batch_name
    })
  }

  select_subject (id, subject_name) {
    console.log(id)
    this.setState({
      subject: id,
      subject_text: subject_name
    })
  }

  select_teacher (id, teacher_name) {
    console.log(id)
    this.setState({
      teacher: id,
      teacher_text: teacher_name
    })
  }

  select_teacher1 (id, teacher_name) {
    console.log(id)
    this.setState({
      teacher1: id,
      teacher_text1: teacher_name
    })
  }

  onSubmitHandler (e) {
    e.preventDefault()
    console.log(this.state.start_time);

    (async () => {
		  const rawResponse = await fetch(Config.SERVER_URL +'/studios/?start_time=' + this.state.start_time + '&duration=' + this.state.duration, {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		    
		  })
		  const content = await rawResponse.json()
      const filteredContent = content?.filter(item => EXCLUDED_STUDIO_IDS?.includes(item?.id) || item?.id > 108 )

		 

		 console.log(content)
		 this.setState({
		 	studios_available: filteredContent,
		 	isLoaded: true
		 })
    })()
  }

  componentDidMount () {
    (async () => {
     
		  const rawResponse = await fetch(Config.SERVER_URL +'/get-batch/', {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		   
		  })
		  const content = await rawResponse.json()

		  this.setState({
		  	batch_available: content
		  })

		  console.log(content)
    })();

    (async () => {
      // console.log(this.state.token);
		  const rawResponse = await fetch(Config.SERVER_URL +'/get-subjects/', {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		    // body: JSON.stringify(data)
		  })
		  const content = await rawResponse.json()

		  this.setState({
		  	subject_available: content
		  })

		  console.log(content)
    })();

    (async () => {
      
		  const rawResponse = await fetch(Config.SERVER_URL +'/get-teachers/', {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		   
		  })
		  const content = await rawResponse.json()

		  this.setState({
		  	teacher_available: content
		  })

		  console.log(content)

		  this.setState({
		  	isLoaded: true
		  })
    })()
  }

  render () {
    if (this.state.login_status === false) {
      return <Redirect to='/' />
    }

    // if (this.state.isLoaded) {
      if (this.state.studios_available != null) {
        return (
          <div>
            <Header />

            <div class='container scheduler'>
              <div class='row'>

                {this.state.studios_available.map((item, i) => (
                  <div class='col-md-3'>
                    <div class='main'>
                      <div class='service'>

                        <div class='row'>
                          <div class='col-md-6' />
                          <div class='col-md-6'>
                            <span><h6>{item.studio_name} </h6> </span>
                            <hr />
                            <span><h6 /> </span>

                          </div>
                        </div>

                        <span> <button class='button button1' onClick={() => this.select_studio(item.id)}>Select Studio</button> </span>
                      </div>
                    </div>
                  </div>

                ))};

              </div>
            </div>

          </div>
        )
      } else {
        
        return (
          <div>
            <Header />

            <div class='container scheduler'>
              <div class='row'>

                <center>
                  <form class='login100-form validate-form' method='POST' onSubmit={this.onSubmitHandler}>
                    <h4 class='login100-form-title p-b-40'>
																						SCHEDULE
                    </h4>

                    <div class='wrap-input100 validate-input m-b-16' data-validate='Please enter Start Time'>
                      <input type='text' name='topic' class='input100' id='usericon' placeholder='Topic' value={this.state.topic} onChange={this.onChangeHandler} />
                      <span class='focus-input100' />
                    </div>

                    <div class='wrap-input100 validate-input m-b-16' data-validate='Please enter Start Time'>
                      <input type='text' name='desc' class='input100' id='usericon' placeholder='Description' value={this.state.desc} onChange={this.onChangeHandler} />
                      <span class='focus-input100' />
                    </div>

                    <div class='wrap-input100 validate-input m-b-16' data-validate='Please enter Start Time'>
                      <input type='datetime-local' name='start_time' class='input100' id='usericon' placeholder='Start Time' value={this.state.start_time} onChange={this.onChangeHandler} />
                      <span class='focus-input100' />
                    </div>

                    <div class='wrap-input100 validate-input m-b-20' data-validate='Please enter Duration'>
                      <span class='btn-show-pass'>
                        <i class='fa fa fa-eye' />
                      </span>
                      <input type='text' name='duration' class='input100' placeholder='Duration' value={this.state.duration} onChange={this.onChangeHandler} />
                      <span class='focus-input100' />
                    </div>


                    <div class='wrap-input100 validate-input m-b-16 dropdown' data-validate='Please enter class topic'>
                      <button class='batch100-form-btn dropdown-toggle' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'> {this.state.teacher_text}</button>
                      <div class='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                        {this.state.teacher_available.map((item, i) => (
                          <option value='{item.teacher_name}' class='dropdown-item' onClick={() => this.select_teacher(item.id, item.teacher_name)} role='button'>{item.teacher_name}</option>
                        ))}

                      </div>
                      <span class='focus-input100' />
                    </div>

                    <div class='wrap-input100 validate-input m-b-16 dropdown' data-validate='Please enter class topic'>
                      <button class='batch100-form-btn dropdown-toggle' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'> {this.state.teacher_text1}</button>
                      <div class='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                        <option value='Select Teacher' class='dropdown-item' onClick={() => this.select_teacher1(null, 'Select Teacher')} role='button'>Select Another Teacher (Optional)</option>
                        {this.state.teacher_available.map((item, i) => (
                          <option value='{item.teacher_name}' class='dropdown-item' onClick={() => this.select_teacher1(item.id, item.teacher_name)} role='button'>{item.teacher_name}</option>
                        ))}

                      </div>
                      <span class='focus-input100' />
                    </div>

                    <div class='wrap-input100 validate-input m-b-16 dropdown' data-validate='Please enter class topic'>
                      <button class='batch100-form-btn dropdown-toggle' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'> {this.state.subject_text}</button>
                      <div class='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                        {this.state.subject_available.map((item, i) => (
                          <option value='{item.subject_name}' class='dropdown-item' onClick={() => this.select_subject(item.id, item.subject_name)} role='button'>{item.subject_name}</option>
                        ))}

                      </div>
                      <span class='focus-input100' />
                    </div>

                    <div class='wrap-input100 validate-input m-b-16 dropdown' data-validate='Please enter class topic'>
                      <button class='batch100-form-btn dropdown-toggle' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'> {this.state.batch_text}</button>
                      <div class='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                        {this.state.batch_available.map((item, i) => (
                          <option value='{item.batch_name}' class='dropdown-item' role='button' onClick={() => this.select_batch(item.id, item.batch_name)}>{item.batch_name}</option>
                        ))}

                      </div>

                      <span class='focus-input100' />
                    </div>
                    <div class='container-login100-form-btn'>
                      <button class='login100-form-btn' type='submit'>
																							View Studios
                      </button>
                    </div>

                  </form>

                </center>

              </div>
            </div>

          </div>
        )
      }
    // } else {
    //   return (<h2>Loading .. </h2>)
    // }
  }
}

export default Scheduler
