import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Config from "../config.json";
import ReactDOM from "react-dom";
import { Multiselect } from "multiselect-react-dropdown";
import Modal from "react-modal";
import ReactLoading from 'react-loading';
import Moment from 'moment';
import '../static/dashboard.css'
import logo2 from '../static/ZinEdu_LOGO.svg'
import '../static/sch.css'
import Header from './Header';
import { EXCLUDED_STUDIO_IDS } from '../utils/constants';
Modal.setAppElement("#root");
class TrashClass extends Component {
	
  constructor () {
    super()
    const token = localStorage.getItem('token')
	
    let login_status = true
    if (token === null) {
      login_status = false
    }
    this.state = {
	  endpoint: 'https://ap.zinedu.com/',
      login_status,
      token,
      isLoaded: true,
	  isLoaded2:false,
	  id: null,
	  reschedule_id: null,
      start_date: null,
	  end_date: null,
	  start_time: null,
	  end_time: null,
	  from_date: null,
	  to_date: null,
      duration: null,
	  confliction_id: null,
      batch_available: [],
      teacher_available: [],
	  group_available: [],
      subject_available: [],
	  studio_available: [],
	  status_available: [],
	  json_list: [],
	  chapter_list: [],
	  tag_list: [],
	  selected_tag_list: [],
	  tags: [],
      teacher: null,
      batch: null,
      topic: null,
      subject: null,
	  chapter: null,
	  studio:null,
	  status:null,
      desc:null,
      teacher_text: 'Teacher',
      subject_text: 'Subject',
      batch_text: 'Batch',
	  studio_text: 'Studio',
	  status_text: 'Status',
	  chapter_text: 'Chapter',
	  group_text: 'Group',
	  tag_text: 'Tag',
	  popup_dialog:false,
	  popup_dialog2: false,	  
    }

	
  }


  onChangeHandler (e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  
  async onSearchHandler (e) {
	this.setState({
	  isLoaded: true
	})
let from_date = this.state.from_date
let to_date = this.state.to_date
let group = this.state.group
let subject = this.state.subject
let studio = this.state.studio
let teacher = this.state.teacher 
let status = this.state.status
let url = '';
let query_string = ''
if(from_date != null && to_date != null){
  query_string = query_string + '&from_date='+from_date+'&to_date='+to_date;
}
if(teacher != 0 && teacher != null){
  query_string = query_string + '&teacher_id='+teacher;
}
if(subject != 0 && subject != null){
  query_string = query_string + '&subject_id='+subject;
}
if(studio != 0 && studio != null){
  query_string = query_string + '&studio_id='+studio;
}
if(group != 0 && group != null){
  query_string = query_string + '&group_id='+group;
}
if(status != 0 && status != null){
  query_string = query_string + '&status_id='+status;
}
if(query_string != null){
  url = '/get-trash-time-table?scheduled=1'+query_string;
}
else{
  url = '/get-trash-time-table?scheduled=1';
}
const rawResponse = await fetch(Config.SERVER_URL +url, {
	  method: 'GET',
	  headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		Authorization: 'Token ' + this.state.token
	  }
	 
	})
	const response = await rawResponse.json()
	this.setState({
		json_list: response.data,
	  isLoaded: false
	})
}
  async onChangeHandlerFromDate (e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  async onChangeHandlerToDate (e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  
  
  onReschedule (e) {
    e.preventDefault()
	this.setState({
		  	isLoaded2: true
		  })
     const data = { id:this.state.reschedule_id, duration:this.state.duration, start_time: this.state.start_time,teacher_id:this.state.teacher };
    (async () => {
		  const rawResponse = await fetch(Config.SERVER_URL +'/reschedule-meeting', {
		    method: 'POST',
			body: JSON.stringify(data),
			dataType: 'json',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		  })
		  const response = await rawResponse.json()
		  this.setState({
		  	isLoaded2: false
		  })
		  if(response.status){
			  alert(response.msg);
			  window.location.reload();
		  }
		  else{
			  alert(response.msg);
		  }
    })()
  }
  
onSelect(selectedList, selectedItem) {
		  let selected_tag_lists = [];
		  for(const s of selectedList){
				  selected_tag_lists.push({'id':s.id,'tag_name':s.tag_name});
		  }
		  this.setState({
		  	selected_tag_list: selected_tag_lists
		  })
}
onRemove(selectedList, removedItem){
	let selected_tag_lists = [];
		  for(const s of selectedList){
				  selected_tag_lists.push({'id':s.id,'tag_name':s.tag_name});
		  }
		  this.setState({
		  	selected_tag_list: selected_tag_lists
		  })
}

show_dialog_modal(data){
	this.setState({
      popup_dialog: true
	  })
	  this.setState({reschedule_id:data.id})
	  this.setState({start_time:data.start_time})
	  this.setState({duration:data.duration})
}
hide_dialog_modal(){
	this.setState({
      popup_dialog: false,
	  })
}

delete_class(id){
     const data = { id:id };
    (async () => {
		  const rawResponse = await fetch(Config.SERVER_URL +'/delete-class', {
		    method: 'POST',
			body: JSON.stringify(data),
			dataType: 'json',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		  })
		  const response = await rawResponse.json()
		  if(response.status){
			  alert(response.msg);
			  window.location.reload();
		  }
		  else{
			  alert(response.msg);
		  }
    })()
}
export_trash_timetable(){
	(async () => {
		  const rawResponse = await fetch(Config.SERVER_URL +'/export_trash_time_table/', {
		    method: 'GET',
			dataType: 'json',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		  })
		  const response = await rawResponse.json()
		  if(response.status){
			  window.location.href = response.file;
		  }
		  else{
			  alert(response.msg);
		  }
    })()
}
  select_batch (id, section_name) {
    this.setState({
      batch: id,
      batch_text: section_name
    })
  }

  select_subject (id, subject_name) {
    this.setState({
      subject: id,
      subject_text: subject_name
    })
	this.get_chapters (id, 'Select chapter');
  }
  
  select_chapter (id, chapter_name) {
    this.setState({
      chapter: id,
      chapter_text: chapter_name
    })
	this.get_tags (id, 'Select tag')
  }

  select_teacher (id, teacher_name) {
    this.setState({
      teacher: id,
      teacher_text: teacher_name
    })
  }

  select_group (id, group_name) {
    this.setState({
      group: id,
      group_text: group_name
    })
  }
  
  select_studio (id, studio_name) {
    this.setState({
      studio: id,
      studio_text: studio_name
    })
  }
  
  select_status (id, status_name) {
    this.setState({
      status: id,
      status_text: status_name
    })
  }

  async select_group2 (id, group_name) {
    this.setState({
      group: id,
      group_text: group_name
    })
	
  }
async select_batch2 (id, section_name) {
    this.setState({
      batch: id,
      batch_text: section_name
    })
	
  }

  async select_subject2 (id, subject_name) {
	  this.setState({
      subject: id,
      subject_text: subject_name
    })
	  }
  
  async select_studio2 (id, studio_name) {
	  this.setState({
      studio: id,
      studio_text: studio_name
    })
	 }

  async select_teacher2 (id, teacher_name) {
    this.setState({
      teacher: id,
      teacher_text: teacher_name
    })
	}
  
  async select_status2 (id, status_name) {
	  this.setState({
      status: id,
      status_text: status_name
    })
	  }
  
  
  async get_chapters (id, chapter_name) {
	  this.setState({
      chapter: id,
      chapter_text: chapter_name
    })
	  let url = '/chapters/'+id+'/';
	  
	  
    const rawResponse = await fetch(Config.SERVER_URL +url, {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		   
		  })
		  const response = await rawResponse.json()
		  this.setState({
		  	chapter_list: response.data
		  })
		   for (const s of response.data){
			  if(s.id == this.state.chapter){
				this.setState({
				chapter_text: s.chapter_name
				});
			  }				  
		  }
		  this.get_tags(this.state.chapter,'Select tags');
  }
  
  
  async get_tags (id, tag_name) {
	  this.setState({
      tag: id,
      tag_text: tag_name
    })
	  let url = '/tags/'+id+'/';
	  
	  
    const rawResponse = await fetch(Config.SERVER_URL +url, {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		   
		  })
		  const response = await rawResponse.json()
		  this.setState({
		  	tag_list: response.data
		  })
		  
		  let selected_tag_lists = [];
		  for(const s of response.data){
			  if(this.state.tags.includes(s.id)){
				  selected_tag_lists.push({'id':s.id,'tag_name':s.tag_name});
			  }
		  }
		  this.setState({
		  	selected_tag_list: selected_tag_lists
		  })
  }
  
 componentDidMount () {
    (async () => {
     
		  const rawResponse = await fetch(Config.SERVER_URL +'/studios/', {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		   
		  })
		  const content = await rawResponse.json()
		  const filteredContent = content?.filter(item => EXCLUDED_STUDIO_IDS?.includes(item?.id) || item?.id > 108 );

		  this.setState({
		  	studio_available: filteredContent
		  })

		  
    })();
	(async () => {
     
		const rawResponse = await fetch(Config.SERVER_URL +'/group-list/', {
		  method: 'GET',
		  headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: 'Token ' + this.state.token
		  }
		 
		})
		const content = await rawResponse.json()

		this.setState({
			group_available: content.data
		})

		
  })();
	(async () => {
     
		  const rawResponse = await fetch(Config.SERVER_URL +'/get-batch/', {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		   
		  })
		  const content = await rawResponse.json()

		  this.setState({
		  	batch_available: content.data
		  })

		  
    })();

    (async () => {
      // console.log(this.state.token);
		  const rawResponse = await fetch(Config.SERVER_URL +'/get-subjects/', {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		    // body: JSON.stringify(data)
		  })
		  const content = await rawResponse.json()

		  this.setState({
		  	subject_available: content.data
		  })

		  
    })();

    (async () => {
      
		  const rawResponse = await fetch(Config.SERVER_URL +'/get-teachers/', {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		   
		  })
		  const content = await rawResponse.json()

		  this.setState({
		  	teacher_available: content.data
		  })
    })();
	
	(async () => {
      
		  const rawResponse = await fetch(Config.SERVER_URL +'/get-class-status/', {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		   
		  })
		  const content = await rawResponse.json()

		  this.setState({
		  	status_available: content.data
		  })
    })();
	
	(async () => {
      
		  const rawResponse = await fetch(Config.SERVER_URL +'/get-trash-time-table?scheduled=1', {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		   
		  })
		  const response = await rawResponse.json()
		  this.setState({
		  	json_list: response.data
		  })
		  this.setState({
		  	isLoaded: false
		  })
		  
    })();
	
  }

  
  render () {
    if (this.state.login_status === false) {
      return <Redirect to='/' />
    }
	
	
	
        
        return (
          <div>
            <Header />
			
			<h4 class='login100-title p-b-40'>
																						Trash Classes
                    </h4>
			  <div class='container scheduler'>
			  <div class='row'>
			  <div class='col-md-12'><a
                        href="javascript:void(0)"
                        onClick={() => this.export_trash_timetable()}
                      >
                        Export
                      </a></div>
			  </div>
              <div class='row'>
			  
<div class='col-md-12'>
{this.state.isLoaded ? <ReactLoading type="cylon" color="#09AEE5" /> : ''}
<table width='100%'>
<thead><th colspan='2'>Date</th><th colspan='2'>Time</th><th colspan='2'>Group</th><th>Subject</th><th colspan='2'>Teacher</th><th colspan='2'>Studio</th><th>Reason</th></thead>
<tbody>
{this.state.json_list.map((data, key) => {
 return (
<tr>
	<td colspan='2'>{Moment(data.start_time).format('DD/MM/YYYY')}</td>
	<td colspan='2'>{Moment(data.start_time).format('HH:mm')}-{Moment(data.end_time).format('HH:mm')}</td>
	<td colspan='2'>{data.group_name}</td>
	<td>{data.subject_name}</td>
	<td colspan='2'>{data.teacher_name}</td>
	<td colspan='2'>{data.studio_name}</td>
	<td>{data.desc} - {data.reason_center_name}</td>

</tr>
          );
        })}
</tbody>
</table>
</div>

              </div>
            </div>

          </div>
        )
  }
}

export default TrashClass
