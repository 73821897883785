import React, { Component } from 'react'
import Config from "../config.json";
import { Link, Redirect } from 'react-router-dom'
import ReactDOM from "react-dom";
import { Multiselect } from "multiselect-react-dropdown";
import Modal from "react-modal";
import ReactLoading from 'react-loading';
import Moment from 'moment';
import '../static/dashboard.css'
import logo2 from '../static/ZinEdu_LOGO.svg'
import '../static/sch.css'
import Header from './Header';
import { EXCLUDED_STUDIO_IDS } from '../utils/constants';
Modal.setAppElement("#root");
class ConflictTimeTable extends Component {
	
  constructor () {
    super()
    const token = localStorage.getItem('token')
	
    let login_status = true
    if (token === null) {
      login_status = false
    }
    this.state = {
	  endpoint: 'https://ap.zinedu.com/',
      login_status,
      token,
      isLoaded: true,
	  json_list: [],
	  timetable_info: [],
	  id: null,
	  conflict_data_info: [],
	  confliction_id: null,
	  start_time: null,
	  start_time: null,
      duration: null,
      group_available: [],
      teacher_available: [],
      subject_available: [],
	  studio_available: [],
	  center_available: [],
	  status_available: [],
	  json_list: [],
	  chapter_list: [],
	  tag_list: [],
	  selected_tag_list: [],
	  tags: [],
      teacher: null,
      group: null,
      topic: null,
      subject: null,
	  chapter: null,
	  studio:null,
	  center: null,
	  schedule_type_id: 0,
	  schedule_type_text: "Online",
	  schedule_type_id2: null,
	  schedule_type_text2: "Class Mode",
	  class_type_id: 0,
	  class_type_text: 'Class Type',
	  status:null,
      desc:null,
      teacher_text: 'Select Teacher',
      subject_text: 'Select Subject',
      group_text: 'Select Group',
	  studio_text: 'Select Studio',
	  status_text: 'Select Status',
	  chapter_text: 'Select Chapter',
	  tag_text: 'Select Tag',
	  popup_dialog:false,
	  popup_dialog2: false,
    }
    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.onSubmitHandler = this.onSubmitHandler.bind(this)
	this.onSubmitHandler2 = this.onSubmitHandler2.bind(this)
	this.onSelect = this.onSelect.bind(this)
	this.onRemove = this.onRemove.bind(this)
  }

  onChangeHandler (e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  
  show_dialog_modal(){
	this.setState({
      popup_dialog: true
	  })
}
hide_dialog_modal(){
	this.setState({
      popup_dialog: false,
	  popup_dialog2: false
	  })
}

select_schedule_type2(id, schedule_type_name) {
    this.setState({
      schedule_type_id2: id,
      schedule_type_text2: schedule_type_name,
    });
  }
  select_schedule_type(id, schedule_type_name) {
    this.setState({
      schedule_type_id: id,
      schedule_type_text: schedule_type_name,
    });
	if(id == 0){
	this.setState({
      center: null,
      center_text: "Select Center",
    });
	}
	else{
		this.setState({
      studio: null,
      studio_text: "Select Studio",
    });
	}
  }
  
  select_class_type(id, class_type_name) {
    this.setState({
      class_type_id: id,
      class_type_text: class_type_name,
    });
  }

select_center (id, center_name) {
    this.setState({
      center: id,
      center_text: center_name
    })
  }
  
  select_group (id, group_name) {
    this.setState({
      group: id,
      group_text: group_name
    })
  }

  select_subject (id, subject_name) {
    this.setState({
      subject: id,
      subject_text: subject_name
    })
	this.get_chapters (id, 'Select chapter');
  }
  
  select_chapter (id, chapter_name) {
    this.setState({
      chapter: id,
      chapter_text: chapter_name
    })
	this.get_tags (id, 'Select tag')
  }

  select_teacher (id, teacher_name) {
    this.setState({
      teacher: id,
      teacher_text: teacher_name
    })
  }
  
  select_studio (id, studio_name) {
    this.setState({
      studio: id,
      studio_text: studio_name
    })
  }
  
  select_status (id, status_name) {
    this.setState({
      status: id,
      status_text: status_name
    })
  }
onSelect(selectedList, selectedItem) {
    this.setState({
		  	selected_tags: selectedList
		  })
		  let selected_tag_lists = [];
		  for(const s of selectedList){
				  selected_tag_lists.push({'id':s.id,'tag_name':s.tag_name});
		  }
		  this.setState({
		  	selected_tag_list: selected_tag_lists
		  })
}
onRemove(selectedList, removedItem){
	let selected_tag_lists = [];
		  for(const s of selectedList){
				  selected_tag_lists.push({'id':s.id,'tag_name':s.tag_name});
		  }
		  this.setState({
		  	selected_tag_list: selected_tag_lists
		  })
}
  async get_chapters (id, chapter_name) {
	  this.setState({
      chapter_text: chapter_name
    })
	  let url = '/chapters/'+id+'/';
	  
	  
    const rawResponse = await fetch(Config.SERVER_URL + url, {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		   
		  })
		  const response = await rawResponse.json()
		  this.setState({
		  	chapter_list: response.data
		  })
		  
		  for (const s of response.data){
			  if(s.id == this.state.chapter){
				this.setState({
				chapter_text: s.chapter_name
				});
			  }				  
		  }
		  
		  this.get_tags(this.state.chapter,'Select tags');
  }
  
  
  async get_tags (id, tag_name) {
	  this.setState({
      tag_text: tag_name
    })
	  let url = '/tags/'+id+'/';
	  
	  
    const rawResponse = await fetch(Config.SERVER_URL + url, {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		   
		  })
		  const response = await rawResponse.json()
		  this.setState({
		  	tag_list: response.data
		  })
		  let selected_tag_lists = [];
		  for(const s of response.data){
			  if(this.state.tags.includes(s.id)){
				  selected_tag_lists.push({'id':s.id,'tag_name':s.tag_name});
			  }
		  }
		  this.setState({
		  	selected_tag_list: selected_tag_lists
		  })
  }
  
  edit_conflict(id){
	  (async () => {
      
		  const rawResponse = await fetch(Config.SERVER_URL + '/get-conflict-timetable-info?id='+id, {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		   
		  })
		  const response = await rawResponse.json()
		  this.setState({
		  	conflict_data_info: response.data,
			confliction_id: response.data.id,
			start_time: response.data.start_time,
			end_time: response.data.end_time,
			duration: response.data.duration,
			center: response.data.center_id,
			group: response.data.group_id,
			subject: response.data.subject_id,
			chapter: response.data.chapter_id,
			teacher: response.data.teacher_id,
			tags: response.data.tags,
			popup_dialog: true
		  });
		  for (const s of this.state.center_available){
			  if(s.id == response.data.center_id){
				this.setState({
				center_text: s.name
				});
			  }				  
		  }
		  for (const s of this.state.subject_available){
			  if(s.id == response.data.subject_id){
				this.setState({
				subject_text: s.subject_name
				});
			  }				  
		  }
		  this.get_chapters (response.data.subject_id, 'Select chapter');
		  for (const s of this.state.group_available){
			  if(s.id == response.data.group_id){
				this.setState({
				group_text: s.name
				});
			  }				  
		  }
		  
		  for (const s of this.state.teacher_available){
			  if(s.id == response.data.teacher_id){
				this.setState({
				teacher_text: s.teacher_name
				});
			  }				  
		  }
		  
		  for (const s of this.state.studio_available){
			  if(s.id == response.data.studio_id){
				this.setState({
				studio_text: s.studio_name
				});
			  }				  
		  }
		  
		  
    })()
  }
  
  
  edit_timetable(id,confliction_id){
	  (async () => {
      
		  const rawResponse = await fetch(Config.SERVER_URL + '/get-timetable-info?id='+id, {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		   
		  })
		  const response = await rawResponse.json()
		  this.setState({
		  	timetable_info: response.data,
			id: response.data.id,
			confliction_id:confliction_id,
			start_time: response.data.start_time,
			end_time: response.data.end_time,
			duration: response.data.duration,
			center: response.data.center_id,
			group: response.data.group_id,
			subject: response.data.subject_id,
			chapter: response.data.chapter_id,
			teacher: response.data.teacher_id,
			tags: response.data.tags,
			popup_dialog2: true
		  });
		  
		  for (const s of this.state.center_available){
			  if(s.id == response.data.center_id){
				this.setState({
				center_text: s.name
				});
			  }				  
		  }
		  
		  for (const s of this.state.subject_available){
			  if(s.id == response.data.subject_id){
				this.setState({
				subject_text: s.subject_name
				});
			  }				  
		  }
		  this.get_chapters (response.data.subject_id, 'Select chapter');
		  for (const s of this.state.group_available){
			  if(s.id == response.data.group_id){
				this.setState({
				group_text: s.name
				});
			  }				  
		  }
		  
		  for (const s of this.state.teacher_available){
			  if(s.id == response.data.teacher_id){
				this.setState({
				teacher_text: s.teacher_name
				});
			  }				  
		  }
		  
		  for (const s of this.state.studio_available){
			  if(s.id == response.data.studio_id){
				this.setState({
				studio_text: s.studio_name
				});
			  }				  
		  }
		  
    })()
  }

onSubmitHandler (e) {
    e.preventDefault()
     const data = { confliction_id:this.state.confliction_id, schedule_type_id: this.state.schedule_type_id,title: this.state.topic, desc: this.state.desc, start_time: this.state.start_time, end_time: this.state.end_time, duration: this.state.duration, studio: this.state.studio, center: this.state.center, teacher: this.state.teacher, group: this.state.group, subject: this.state.subject,class_type: this.state.class_type_id, chapter:this.state.chapter, selected_tags:this.state.selected_tag_list };
    (async () => {
		  const rawResponse = await fetch(Config.SERVER_URL + '/create-timetable', {
		    method: 'POST',
			body: JSON.stringify(data),
			dataType: 'json',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		  })
		  const response = await rawResponse.json()
		  if(response.status){
			  alert(response.msg);
			  window.location.href='/#/timetable'
		  }
		  else{
			  alert(response.msg);
		  }
    })()
  }
  
  onSubmitHandler2 (e) {
    e.preventDefault()
     const data = { id:this.state.id, confliction_id:this.state.confliction_id, title: this.state.topic, desc: this.state.desc, start_time: this.state.start_time, end_time: this.state.end_time, duration: this.state.duration, studio: this.state.studio, center: this.state.center, teacher: this.state.teacher, group: this.state.group, subject: this.state.subject, chapter:this.state.chapter, selected_tags:this.state.selected_tag_list };
    (async () => {
		  const rawResponse = await fetch(Config.SERVER_URL + '/save-timetable', {
		    method: 'POST',
			body: JSON.stringify(data),
			dataType: 'json',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		  })
		  const response = await rawResponse.json()
		  if(response.status){
			  alert(response.msg);
			  window.location.href='/#/timetable'
		  }
		  else{
			  alert(response.msg);
		  }
    })()
  }
  
  componentDidMount () {
	  (async () => {
      
		  const rawResponse = await fetch(Config.SERVER_URL + '/get-conflict-timetable', {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		   
		  })
		  const response = await rawResponse.json()
		  this.setState({
		  	json_list: response.data
		  })
		  this.setState({
		  	isLoaded: false
		  })
		  
    })();
	
	(async () => {
     
		  const rawResponse = await fetch(Config.SERVER_URL + '/studios/', {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		   
		  })
		  const content = await rawResponse.json()
		  const filteredContent = content?.filter(item => EXCLUDED_STUDIO_IDS?.includes(item?.id) || item?.id > 108 );

		  this.setState({
		  	studio_available: filteredContent
		  })

		  
    })();
	
	(async () => {
		  const rawResponse = await fetch(Config.SERVER_URL + '/get-center/', {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		  })
		  const content = await rawResponse.json()

		  this.setState({
		  	center_available: content.data
		  })

		  
    })();
	
	(async () => {
     
		  const rawResponse = await fetch(Config.SERVER_URL + '/group-list/', {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		   
		  })
		  const content = await rawResponse.json()

		  this.setState({
		  	group_available: content.data
		  })

		  
    })();

    (async () => {
      // console.log(this.state.token);
		  const rawResponse = await fetch(Config.SERVER_URL + '/get-subjects/', {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		    // body: JSON.stringify(data)
		  })
		  const content = await rawResponse.json()

		  this.setState({
		  	subject_available: content.data
		  })

		  
    })();

    (async () => {
      
		  const rawResponse = await fetch(Config.SERVER_URL + '/get-teachers/', {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		   
		  })
		  const content = await rawResponse.json()

		  this.setState({
		  	teacher_available: content.data
		  })
    })();
	
	(async () => {
      
		  const rawResponse = await fetch(Config.SERVER_URL + '/get-class-status/', {
		    method: 'GET',
		    headers: {
		      Accept: 'application/json',
		      'Content-Type': 'application/json',
		      Authorization: 'Token ' + this.state.token
		    }
		   
		  })
		  const content = await rawResponse.json()

		  this.setState({
		  	status_available: content.data
		  })
    })();
     }

  render () {
    if (this.state.login_status === false) {
      return <Redirect to='/' />
    }
	
	
	
        
        return (
          <div>
            <Header />
			
			{this.state.popup_dialog && ( 
			<div className="modal-dialogs modal-lg" id="dialog-modals">
            <form
              className="login100 validate-form"
              id="frmTimeTable"
              method="POST"
              onSubmit={this.onSubmitHandler}
            >
              <h4 className="login100-title p-b-40">Create Time-Table</h4>
              <div
                className="wrap-input100 validate-input m-b-16"
                data-validate="Please enter Start Time"
              >
                <input
                  type="datetime-local"
                  name="start_time"
                  className="input100"
                  id="usericon"
                  placeholder="Start Time"
                  value={this.state.start_time}
                  onChange={this.onChangeHandler}
                />
                <span className="focus-input100" />
              </div>

              <div
                className="wrap-input100 validate-input m-b-20"
                data-validate="Please enter Duration"
              >
                <span className="btn-show-pass">
                  <i className="fa fa fa-eye" />
                </span>
                <input
                  type="text"
                  name="duration"
                  className="input100"
                  placeholder="Duration (in minutes)"
                  value={this.state.duration}
                  onChange={this.onChangeHandler}
                />
                <span className="focus-input100" />
              </div>

              <div
                className="wrap-input100 validate-input m-b-16 dropdown"
                data-validate="Please Subject"
              >
                <button
                  className="batch100-form-btn dropdown-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {" "}
                  {this.state.subject_text}
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <option
                    value="0"
                    className="dropdown-item"
                    onClick={() => this.select_subject(0, "Subject")}
                    role="button"
                  >
                    Subject
                  </option>
                  {this.state.subject_available.map((item, i) => (
                    <option
                      value="{item.subject_name}"
                      className="dropdown-item"
                      onClick={() =>
                        this.select_subject(item.id, item.subject_name)
                      }
                      role="button"
                    >
                      {item.subject_name}
                    </option>
                  ))}
                </div>
                <span className="focus-input100" />
              </div>

              <div
                className="wrap-input100 validate-input m-b-16 dropdown"
                data-validate="Please select chapter"
              >
                <button
                  className="batch100-form-btn dropdown-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {" "}
                  {this.state.chapter_text}
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <option
                    value="0"
                    className="dropdown-item"
                    onClick={() => this.select_chapter(0, "Select Chapter")}
                    role="button"
                  >
                    Select Chapter
                  </option>
                  {this.state.chapter_list.map((item, i) => (
                    <option
                      value="{item.chapter_name}"
                      className="dropdown-item"
                      onClick={() =>
                        this.select_chapter(item.id, item.chapter_name)
                      }
                      role="button"
                    >
                      {item.chapter_name}
                    </option>
                  ))}
                </div>
                <span className="focus-input100" />
              </div>

              <div
                className="wrap-input100 validate-input m-b-16 dropdown"
                data-validate="Subtopics"
              >
                <Multiselect
                  options={this.state.tag_list}
                  closeIcon="close"
                  displayValue="tag_name"
                  selectedValues={this.state.selected_tag_list}
                  onSelect={this.onSelect}
                  onRemove={this.onRemove}
                  selectionLimit="5"
                  avoidHighlightFirstOption
                  name="tagList"
                />
                <span className="focus-input100" />
              </div>

              <div
                className="wrap-input100 validate-input m-b-16 dropdown"
                data-validate="Please Teacher"
              >
                <button
                  className="batch100-form-btn dropdown-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {" "}
                  {this.state.teacher_text}
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <option
                    value="0"
                    className="dropdown-item"
                    onClick={() => this.select_teacher(0, "Teacher")}
                    role="button"
                  >
                    Teacher
                  </option>
                  {this.state.teacher_available.map((item, i) => (
                    <option
                      value="{item.teacher_name}"
                      className="dropdown-item"
                      onClick={() =>
                        this.select_teacher(item.id, item.teacher_name)
                      }
                      role="button"
                    >
                      {item.teacher_name}
                    </option>
                  ))}
                </div>
                <span className="focus-input100" />
              </div>

              <div
                className="wrap-input100 validate-input m-b-16 dropdown"
                data-validate="Please Group"
              >
                <button
                  className="batch100-form-btn dropdown-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {" "}
                  {this.state.group_text}
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <option
                    value="0"
                    className="dropdown-item"
                    onClick={() => this.select_group(0, "Group")}
                    role="button"
                  >
                    Group
                  </option>
                  {this.state.group_available.map((item, i) => (
                    <option
                      value="{item.name}"
                      className="dropdown-item"
                      role="button"
                      onClick={() => this.select_group(item.id, item.name)}
                    >
                      {item.name}
                    </option>
                  ))}
                </div>

                <span className="focus-input100" />
              </div>


              <div style={{border: '0.5px solid black', padding: '10px', paddingRight: '0'}}>
                    <span style={{marginLeft: '25px'}}>
                      Location
                    </span>

                    <div
                className="wrap-input100 validate-input m-b-16 dropdown"
                data-validate="Please Mode"
              >
                <button
                  className="batch100-form-btn dropdown-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {" "}
                  {this.state.schedule_type_text}
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <option
                    value="0"
                    className="dropdown-item"
                    onClick={() => this.select_schedule_type(0, "Online")}
                    role="button"
                  >
                    Online
                  </option>
                  <option
                    value="0"
                    className="dropdown-item"
                    onClick={() => this.select_schedule_type(1, "Offline")}
                    role="button"
                  >
                    Offline
                  </option>
                </div>

                <span className="focus-input100" />
              </div>

              {this.state.schedule_type_id == 0 && (
                <div
                  className="wrap-input100 validate-input m-b-16 dropdown"
                  data-validate="Please Studio"
                >
                  <button
                    className="batch100-form-btn dropdown-toggle"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {" "}
                    {this.state.studio_text}
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <option
                      value="0"
                      className="dropdown-item"
                      onClick={() => this.select_studio(0, "Studio")}
                      role="button"
                    >
                      Studio
                    </option>
                    {this.state.studio_available.map((item, i) => (
                      <option
                        value="{item.studio_name}"
                        className="dropdown-item"
                        onClick={() =>
                          this.select_studio(item.id, item.studio_name)
                        }
                        role="button"
                      >
                        {item.studio_name}
                      </option>
                    ))}
                  </div>
                  <span className="focus-input100" />
                </div>
              )}

              {this.state.schedule_type_id == 1 && (
                <div
                  className="wrap-input100 validate-input m-b-16 dropdown endtime-div"
                  data-validate="Please select center"
                >
                  <button
                    className="batch100-form-btn dropdown-toggle"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {" "}
                    {this.state.center_text}
                  </button>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <option
                      value="0"
                      className="dropdown-item"
                      onClick={() => this.select_center(0, "Center")}
                      role="button"
                    >
                      Select Center
                    </option>
                    {this.state.center_available.map((item, i) => (
                      <option
                        value="{item.name}"
                        className="dropdown-item"
                        onClick={() => this.select_center(item.id, item.name)}
                        role="button"
                      >
                        {item.name}
                      </option>
                    ))}
                  </div>
                  <span className="focus-input100" />
                </div>
              )}
              </div>
              




              <div
                className="wrap-input100 validate-input m-b-16 dropdown"
                data-validate="Class Tag"
              >
                <button
                  className="batch100-form-btn dropdown-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {" "}
                  {this.state.class_type_text}
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <option
                    value="0"
                    className="dropdown-item"
                    onClick={() => this.select_class_type(1, "Lecture")}
                    role="button"
                  >
                    Lecture
                  </option>
                  <option
                    value="0"
                    className="dropdown-item"
                    onClick={() => this.select_class_type(2, "Tutorial")}
                    role="button"
                  >
                    Tutorial
                  </option>
                  <option
                    value="0"
                    className="dropdown-item"
                    onClick={() => this.select_class_type(3, "Doubt")}
                    role="button"
                  >
                    Doubt
                  </option>
                </div>

                <span className="focus-input100" />
              </div>

              <div
                className="wrap-input100 validate-input m-b-16 endtime-div"
                data-validate="Please enter End Time"
              >
                <input
                  type="datetime-local"
                  name="end_time"
                  className="input100"
                  id="end_time"
                  placeholder="Start Time"
                  value={this.state.end_time}
                  onChange={this.onChangeHandler}
                />
                <span className="focus-input100" />
              </div>

              <div></div>

              <div className="container-login100-form-btn">
                <button className="login100-form-btn" type="submit">
                  Create
                </button>
                <button
                  className="login100-form-btn"
                  onClick={() => this.hide_dialog_modal()}
                >
                  Close
                </button>
                {this.state.isLoaded2 ? (
                  <ReactLoading type="cylon" color="#09AEE5" />
                ) : (
                  ""
                )}
              </div>
            </form>
          </div>
)}


{this.state.popup_dialog2 && ( 
			<div class='modal-dialogs' id='dialog-modals'>
                  <form class='login100 validate-form' id="frmTimeTable" method='POST' onSubmit={this.onSubmitHandler2}>
                    <h4 class='login100-title p-b-40'>
																						Edit Time-Table
                    </h4>
					<div class='wrap-input100 validate-input m-b-16 dropdown endtime-div' data-validate='Please select center'>
                      <button class='batch100-form-btn dropdown-toggle' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'> {this.state.center_text}</button>
                      <div class='dropdown-menu' aria-labelledby='dropdownMenuButton'>
					  <option value='0' class='dropdown-item' onClick={() => this.select_center(0, 'Select Subject')} role='button'>Select Center</option>
                        {this.state.center_available.map((item, i) => (
                          <option value='{item.name}' class='dropdown-item' onClick={() => this.select_center(item.id, item.name)} role='button'>{item.name}</option>
                        ))}

                      </div>
                      <span class='focus-input100' />
                    </div>
                    <div class='wrap-input100 validate-input m-b-16 dropdown' data-validate='Please select subject'>
                      <button class='batch100-form-btn dropdown-toggle' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'> {this.state.subject_text}</button>
                      <div class='dropdown-menu' aria-labelledby='dropdownMenuButton'>
					  <option value='0' class='dropdown-item' onClick={() => this.select_subject(0, 'Select Subject')} role='button'>Select Subject</option>
                        {this.state.subject_available.map((item, i) => (
					  <option value='{item.subject_name}' class='dropdown-item' onClick={() => this.select_subject(item.id, item.subject_name)} role='button'>{item.subject_name}</option>
                        ))}

                      </div>
                      <span class='focus-input100' />
                    </div>
					
					<div class='wrap-input100 validate-input m-b-16 dropdown' data-validate='Please select chapter'>
                      <button class='batch100-form-btn dropdown-toggle' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'> {this.state.chapter_text}</button>
                      <div class='dropdown-menu' aria-labelledby='dropdownMenuButton'>
					  <option value='0' class='dropdown-item' onClick={() => this.select_chapter(0, 'Select Chapter')} role='button'>Select Chapter</option>
                        {this.state.chapter_list.map((item, i) => (
                          <option value='{item.chapter_name}' class='dropdown-item' onClick={() => this.select_chapter(item.id, item.chapter_name)} role='button'>{item.chapter_name}</option>
                        ))}

                      </div>
                      <span class='focus-input100' />
                    </div>
					
					<div class='wrap-input100 validate-input m-b-16 dropdown' data-validate='Please select tags'>
                      
                      <Multiselect
              options={this.state.tag_list}
              closeIcon="close"
              displayValue="tag_name"
			  selectedValues={this.state.selected_tag_list}
			  onSelect={this.onSelect}
			  onRemove={this.onRemove}
			  selectionLimit="5"
              avoidHighlightFirstOption
			  name="tagList" 
            />
                      <span class='focus-input100' />
                    </div>
					
					
					

                    <div class='wrap-input100 validate-input m-b-16' data-validate='Please enter Start Time'>
                      <input type='datetime-local' name='start_time' class='input100' id='usericon' placeholder='Start Time' value={this.state.start_time} onChange={this.onChangeHandler} />
                      <span class='focus-input100' />
                    </div>
					
					<div class='wrap-input100 validate-input m-b-16 endtime-div' data-validate='Please enter End Time'>
                      <input type='datetime-local' name='end_time' class='input100' id='end_time' placeholder='Start Time' value={this.state.end_time} onChange={this.onChangeHandler} />
                      <span class='focus-input100' />
                    </div>

                    <div class='wrap-input100 validate-input m-b-20' data-validate='Please enter Duration'>
                      <span class='btn-show-pass'>
                        <i class='fa fa fa-eye' />
                      </span>
                      <input type='text' name='duration' class='input100' placeholder='Duration (in minutes)' value={this.state.duration} onChange={this.onChangeHandler} />
                      <span class='focus-input100' />
                    </div>
					
					<div class='wrap-input100 validate-input m-b-16 dropdown' data-validate='Please select studio'>
                      <button class='batch100-form-btn dropdown-toggle' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'> {this.state.studio_text}</button>
                      <div class='dropdown-menu' aria-labelledby='dropdownMenuButton'>
					  <option value='0' class='dropdown-item' onClick={() => this.select_studio(0, 'Select Studio')} role='button'>Select Studio</option>
                        {this.state.studio_available.map((item, i) => (
                          <option value='{item.studio_name}' class='dropdown-item' onClick={() => this.select_studio(item.id, item.studio_name)} role='button'>{item.studio_name}</option>
                        ))}

                      </div>
                      <span class='focus-input100' />
                    </div>


                    <div class='wrap-input100 validate-input m-b-16 dropdown' data-validate='Please select teacher'>
                      <button class='batch100-form-btn dropdown-toggle' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'> {this.state.teacher_text}</button>
                      <div class='dropdown-menu' aria-labelledby='dropdownMenuButton'>
					  <option value='0' class='dropdown-item' onClick={() => this.select_teacher(0, 'Select Teacher')} role='button'>Select Teacher</option>
                        {this.state.teacher_available.map((item, i) => (
                          <option value='{item.teacher_name}' class='dropdown-item' onClick={() => this.select_teacher(item.id, item.teacher_name)} role='button'>{item.teacher_name}</option>
                        ))}

                      </div>
                      <span class='focus-input100' />
                    </div>

                    <div class='wrap-input100 validate-input m-b-16 dropdown' data-validate='Please select group'>
                      <button class='batch100-form-btn dropdown-toggle' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'> {this.state.group_text}</button>
                      <div class='dropdown-menu' aria-labelledby='dropdownMenuButton'>
					  <option value='0' class='dropdown-item' onClick={() => this.select_group(0, 'Select Group')} role='button'>Select Group</option>
                        {this.state.group_available.map((item, i) => (
                          <option value='{item.name}' class='dropdown-item' role='button' onClick={() => this.select_group(item.id, item.name)}>{item.name}</option>
                        ))}

                      </div>

                      <span class='focus-input100' />
                    </div>
                    <div class='container-login100-form-btn'>
                      <button class='login100-form-btn' type='submit'>Update</button>
					  <button class='login100-form-btn' onClick={() => this.hide_dialog_modal()}>Close</button>
                    </div>
                  </form>
</div>
)}
				
            <div class='container scheduler'>
              <div class='row'>

<div class='col-md-12'>
 <h4 class='login100-title p-b-40'>
																						Conflict Time-Table
                    </h4>
{this.state.isLoaded ? <ReactLoading type="cylon" color="#09AEE5" /> : ''}
<table width='100%'>

<thead><th>Date</th><th>Time</th><th>Group</th><th>Subject</th><th>Teacher</th><th>Studio</th><th>Conflict</th><th>Action</th></thead>
<tbody>
{this.state.json_list.map((data, key) => {
 return (
<tr>
	<td colspan="8">
<table border="1">
<tr><td colspan="8"><strong>Conflict {key+1}</strong></td></tr>
<tr>
	<td>{Moment(data.start_time).format('DD/MM/yy')}</td>
	<td>{Moment(data.start_time).format('HH:mm')}-{Moment(data.end_time).format('HH:mm')}</td>
	<td>{data.group_name}</td>
	<td>{data.subject_name}</td>
	<td>{data.teacher_name}</td>
	<td>{data.studio_name}</td>
	<td>{data.errormsg}</td>
	<td><button onClick={() => this.edit_conflict(data.id)}>Edit</button></td>

</tr>

<tr>
	<td>{Moment(data.conflicted_class.start_time).format('DD/MM/yy')}</td>
	<td>{Moment(data.conflicted_class.start_time).format('HH:mm')}-{Moment(data.conflicted_class.end_time).format('HH:mm')}</td>
	<td>{data.conflicted_class.group_name}</td>
	<td>{data.conflicted_class.subject_name}</td>
	<td>{data.conflicted_class.teacher_name}</td>
	<td>{data.conflicted_class.studio_name}</td>
	<td></td>
	<td><button onClick={() => this.edit_timetable(data.conflicted_class.id,data.id)}></button></td>
</tr>
</table>
</td></tr>
          );
        })}
</tbody>
</table>
</div>

              </div>
            </div>

          </div>
        )
  }
}

export default ConflictTimeTable
