import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Config from "../config.json";
import ReactDOM from "react-dom";
import { Multiselect } from "multiselect-react-dropdown";
import Modal from "react-modal";
import ReactLoading from "react-loading";
import Moment from "moment";
import "../static/dashboard.css";
import logo2 from "../static/ZinEdu_LOGO.svg";
import "../static/sch.css";
import Header from "./Header";
import { EXCLUDED_STUDIO_IDS } from "../utils/constants";
Modal.setAppElement("#root");
class UnscheduleClass extends Component {
  constructor() {
    super();
    const token = localStorage.getItem("token");

    let login_status = true;
    if (token === null) {
      login_status = false;
    }
    this.state = {
      endpoint: "https://ap.zinedu.com/",
      login_status,
      token,
      isLoaded: true,
      isLoaded2: false,
      isLoading: false,
      id: null,
      start_date: null,
      end_date: null,
      start_time: null,
      end_time: null,
      from_date: null,
      to_date: null,
      duration: null,
      confliction_id: null,
      batch_available: [],
      group_available: [],
      teacher_available: [],
      subject_available: [],
      studio_available: [],
      status_available: [],
      json_list: [],
      chapter_list: [],
      tag_list: [],
      selected_tag_list: [],
      tags: [],
      teacher: null,
      batch: null,
      topic: null,
      subject: null,
      chapter: null,
      studio: null,
      status: null,
      desc: null,
      teacher_text: "Teacher",
      subject_text: "Subject",
      batch_text: "Batch",
      studio_text: "Studio",
      status_text: "Status",
      chapter_text: "Chapter",
      group_text: "Group",
      tag_text: "Tag",
      popup_dialog: false,
      popup_dialog2: false,
      order_by: "",
      order_field: "start_time",
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onChangeHandlerFromDate = this.onChangeHandlerFromDate.bind(this);
    this.onChangeHandlerToDate = this.onChangeHandlerToDate.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.onSearchHandler = this.onSearchHandler.bind(this);
  }

  async onSearchHandler(e) {
    this.setState({
      isLoaded: true,
    });
    let from_date = this.state.from_date;
    let to_date = this.state.to_date;
    let group = this.state.group;
    let subject = this.state.subject;
    let studio = this.state.studio;
    let teacher = this.state.teacher;
    let status = this.state.status;
    let url = "";
    let query_string = "";
    if (from_date != null && to_date != null) {
      query_string =
        query_string + "&from_date=" + from_date + "&to_date=" + to_date;
    }
    if (teacher != 0 && teacher != null) {
      query_string = query_string + "&teacher_id=" + teacher;
    }
    if (subject != 0 && subject != null) {
      query_string = query_string + "&subject_id=" + subject;
    }
    if (studio != 0 && studio != null) {
      query_string = query_string + "&studio_id=" + studio;
    }
    if (group != 0 && group != null) {
      query_string = query_string + "&group_id=" + group;
    }
    if (status != 0 && status != null) {
      query_string = query_string + "&status_id=" + status;
    }
    query_string =
      query_string +
      "&order_by=" +
      this.state.order_by +
      "&order_field=" +
      this.state.order_field;
    if (query_string != null) {
      url = "/get-time-table?scheduled=2" + query_string;
    } else {
      url = "/get-time-table?scheduled=2";
    }
    const rawResponse = await fetch(Config.SERVER_URL + url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + this.state.token,
      },
    });
    const response = await rawResponse.json();
    this.setState({
      json_list: response.data,
      isLoaded: false,
    });
  }

  onChangeHandler(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  async onChangeHandlerFromDate(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  async onChangeHandlerToDate(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSelect(selectedList, selectedItem) {
    let selected_tag_lists = [];
    for (const s of selectedList) {
      selected_tag_lists.push({ id: s.id, tag_name: s.tag_name });
    }
    this.setState({
      selected_tag_list: selected_tag_lists,
    });
  }
  onRemove(selectedList, removedItem) {
    let selected_tag_lists = [];
    for (const s of selectedList) {
      selected_tag_lists.push({ id: s.id, tag_name: s.tag_name });
    }
    this.setState({
      selected_tag_list: selected_tag_lists,
    });
  }

  onSubmitHandler(e) {
    e.preventDefault();
    this.setState({
      isLoaded2: true,
    });
    const data = {
      from_date: this.state.from_date,
      to_date: this.state.to_date,
      filter: "date_range",
    };
    (async () => {
      const rawResponse = await fetch(
        Config.SERVER_URL + "/scheduler/create-scheduling",
        {
          method: "POST",
          body: JSON.stringify(data),
          dataType: "json",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Token " + this.state.token,
          },
        }
      );
      const response = await rawResponse.json();
      this.setState({
        isLoaded2: false,
      });
      if (response.status) {
        alert(response.msg);
        window.location.href = "/#/schedule-class";
      } else {
        alert(response.msg);
      }
    })();
  }

  async select_order(order_field, order_by) {
    this.setState({
      order_field: order_field,
    });
    this.setState({
      order_by: order_by,
    });
  }
  show_dialog_modal() {
    this.setState({
      popup_dialog: true,
    });
  }
  hide_dialog_modal() {
    this.setState({
      popup_dialog: false,
    });
  }
  select_batch(id, section_name) {
    this.setState({
      batch: id,
      batch_text: section_name,
    });
  }

  select_group(id, group_name) {
    this.setState({
      group: id,
      group_text: group_name,
    });
  }

  async select_group2(id, group_name) {
    this.setState({
      group: id,
      group_text: group_name,
    });
  }

  select_subject(id, subject_name) {
    this.setState({
      subject: id,
      subject_text: subject_name,
    });
    this.get_chapters(id, "Select chapter");
  }

  select_chapter(id, chapter_name) {
    this.setState({
      chapter: id,
      chapter_text: chapter_name,
    });
    this.get_tags(id, "Select tag");
  }

  select_teacher(id, teacher_name) {
    this.setState({
      teacher: id,
      teacher_text: teacher_name,
    });
  }

  select_studio(id, studio_name) {
    this.setState({
      studio: id,
      studio_text: studio_name,
    });
  }

  select_status(id, status_name) {
    this.setState({
      status: id,
      status_text: status_name,
    });
  }

  async select_batch2(id, section_name) {
    this.setState({
      batch: id,
      batch_text: section_name,
    });
  }

  async select_subject2(id, subject_name) {
    this.setState({
      subject: id,
      subject_text: subject_name,
    });
  }

  async select_studio2(id, studio_name) {
    this.setState({
      studio: id,
      studio_text: studio_name,
    });
  }

  async select_teacher2(id, teacher_name) {
    this.setState({
      teacher: id,
      teacher_text: teacher_name,
    });
  }

  async select_status2(id, status_name) {
    this.setState({
      status: id,
      status_text: status_name,
    });
  }

  async get_chapters(id, chapter_name) {
    this.setState({
      chapter: id,
      chapter_text: chapter_name,
    });
    let url = "/chapters/" + id + "/";

    const rawResponse = await fetch(Config.SERVER_URL + url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + this.state.token,
      },
    });
    const response = await rawResponse.json();
    this.setState({
      chapter_list: response.data,
    });
    for (const s of response.data) {
      if (s.id == this.state.chapter) {
        this.setState({
          chapter_text: s.chapter_name,
        });
      }
    }
    this.get_tags(this.state.chapter, "Select tags");
  }

  async get_tags(id, tag_name) {
    this.setState({
      tag: id,
      tag_text: tag_name,
    });
    let url = "/tags/" + id + "/";

    const rawResponse = await fetch(Config.SERVER_URL + url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + this.state.token,
      },
    });
    const response = await rawResponse.json();
    this.setState({
      tag_list: response.data,
    });

    let selected_tag_lists = [];
    for (const s of response.data) {
      if (this.state.tags.includes(s.id)) {
        selected_tag_lists.push({ id: s.id, tag_name: s.tag_name });
      }
    }
    this.setState({
      selected_tag_list: selected_tag_lists,
    });
  }

  schedule_time(id) {
    const data = { id: id };
    (async () => {
      this.setState({isLoading:true})
      const rawResponse = await fetch(Config.SERVER_URL + "/create-meeting", {
        method: "POST",
        body: JSON.stringify(data),
        dataType: "json",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + this.state.token,
        },
      });
      const response = await rawResponse.json();
      if (response.status) {
        alert(response.msg);
        this.setState({isLoading:false})
        window.location.href = "/#/schedule-class";
      }
    })();
  }

  componentDidMount() {
    (async () => {
      const rawResponse = await fetch(Config.SERVER_URL + "/studios/", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + this.state.token,
        },
      });
      const content = await rawResponse.json();
      const filteredContent = content?.filter(item => EXCLUDED_STUDIO_IDS?.includes(item?.id) || item?.id > 108 );

      this.setState({
        studio_available: filteredContent,
      });
    })();
    (async () => {
      const rawResponse = await fetch(Config.SERVER_URL + "/group-list/", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + this.state.token,
        },
      });
      const content = await rawResponse.json();

      this.setState({
        group_available: content.data,
      });
    })();
    (async () => {
      const rawResponse = await fetch(Config.SERVER_URL + "/get-batch/", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + this.state.token,
        },
      });
      const content = await rawResponse.json();

      this.setState({
        batch_available: content.data,
      });
    })();

    (async () => {
      // console.log(this.state.token);
      const rawResponse = await fetch(Config.SERVER_URL + "/get-subjects/", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + this.state.token,
        },
        // body: JSON.stringify(data)
      });
      const content = await rawResponse.json();

      this.setState({
        subject_available: content.data,
      });
    })();

    (async () => {
      const rawResponse = await fetch(Config.SERVER_URL + "/get-teachers/", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + this.state.token,
        },
      });
      const content = await rawResponse.json();

      this.setState({
        teacher_available: content.data,
      });
    })();

    (async () => {
      const rawResponse = await fetch(
        Config.SERVER_URL + "/get-class-status/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Token " + this.state.token,
          },
        }
      );
      const content = await rawResponse.json();

      this.setState({
        status_available: content.data,
      });
    })();

    (async () => {
      const rawResponse = await fetch(
        Config.SERVER_URL + "/get-time-table?scheduled=2",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Token " + this.state.token,
          },
        }
      );
      const response = await rawResponse.json();
      this.setState({
        json_list: response.data,
      });
      this.setState({
        isLoaded: false,
      });
    })();
  }

  render() {
    if (this.state.login_status === false) {
      return <Redirect to="/" />;
    }

    return (
      <div>
        <Header />

        {this.state.popup_dialog && (
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-body p-3">
                  <form
                    class="login100 validate-form"
                    id="frmTimeTable"
                    method="POST"
                    onSubmit={this.onSubmitHandler}
                  >
                    <h4 class="login100-title p-b-40">Schedule Bulk Classes</h4>

                    <div
                      class="wrap-input100 validate-input m-b-16"
                      data-validate="Please enter From Date"
                    >
                      <input
                        type="date"
                        name="from_date"
                        class="input100"
                        id="usericon"
                        placeholder="From Date"
                        value={this.state.from_date}
                        onChange={this.onChangeHandler}
                      />
                      <span class="focus-input100" />
                    </div>

                    <div
                      class="wrap-input100 validate-input m-b-16"
                      data-validate="Please enter To Date"
                    >
                      <input
                        type="date"
                        name="to_date"
                        class="input100"
                        id="end_time"
                        placeholder="To Date"
                        value={this.state.to_date}
                        onChange={this.onChangeHandler}
                      />
                      <span class="focus-input100" />
                    </div>

                    <div class="container-login100-form-btn">
                      <button class="login100-form-btn" type="submit">
                        Schedule
                      </button>
                      <button
                        class="login100-form-btn"
                        onClick={() => this.hide_dialog_modal()}
						data-dismiss="modal"
                      >
                        Close
                      </button>

                      {this.state.isLoaded2 ? (
                        <ReactLoading type="cylon" color="#09AEE5" />
                      ) : (
                        ""
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}

        <div class="container scheduler">
          <div class="row">
            <div class="col-md-12">
              <br />
              <table width="100%">
                <thead>
                  <tr>
                    <td>
                      <a
                        href="javascript:void(0)"
                        onClick={() => this.show_dialog_modal()}
						data-toggle="modal"
						data-target="#exampleModal"
                      >
                        Schedule Bulk Classes
                      </a>
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
            <div class="col-md-12">
              {this.state.isLoaded ? (
                <ReactLoading type="cylon" color="#09AEE5" />
              ) : (
                ""
              )}
              <table width="100%">
                <thead>
                  <th colspan="2">
                    <input
                      type="date"
                      name="from_date"
                      class="input100"
                      id="usericon"
                      placeholder="From Date"
                      value={this.state.from_date}
                      onChange={this.onChangeHandlerFromDate}
                    />
                  </th>
                  <th colspan="2">
                    <input
                      type="date"
                      name="to_date"
                      class="input100"
                      id="usericon"
                      placeholder="To Date"
                      value={this.state.to_date}
                      onChange={this.onChangeHandlerToDate}
                    />
                  </th>
                  <th colspan="2">
                    <button
                      class="batch100-form-btn dropdown-toggle"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      {this.state.group_text}
                    </button>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <option
                        value="0"
                        class="dropdown-item"
                        onClick={() => this.select_group2(0, "Group")}
                        role="button"
                      >
                        All
                      </option>
                      {this.state.group_available.map((item, i) => (
                        <option
                          value="{item.id}"
                          class="dropdown-item"
                          role="button"
                          onClick={() => this.select_group2(item.id, item.name)}
                        >
                          {item.name}
                        </option>
                      ))}
                    </div>
                  </th>
                  <th colspan="2">
                    {" "}
                    <button
                      class="batch100-form-btn dropdown-toggle"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      {this.state.subject_text}
                    </button>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <option
                        value="0"
                        class="dropdown-item"
                        onClick={() =>
                          this.select_subject2(0, "Select Subject")
                        }
                        role="button"
                      >
                        All
                      </option>
                      {this.state.subject_available.map((item, i) => (
                        <option
                          value="{item.id}"
                          class="dropdown-item"
                          onClick={() =>
                            this.select_subject2(item.id, item.subject_name)
                          }
                          role="button"
                        >
                          {item.subject_name}
                        </option>
                      ))}
                    </div>
                  </th>
                  <th colspan="2">
                    <button
                      class="batch100-form-btn dropdown-toggle"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      {this.state.teacher_text}
                    </button>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <option
                        value="0"
                        class="dropdown-item"
                        onClick={() =>
                          this.select_teacher2(0, "Select Teacher")
                        }
                        role="button"
                      >
                        All
                      </option>
                      {this.state.teacher_available.map((item, i) => (
                        <option
                          value="{item.id}"
                          class="dropdown-item"
                          onClick={() =>
                            this.select_teacher2(item.id, item.teacher_name)
                          }
                          role="button"
                        >
                          {item.teacher_name}
                        </option>
                      ))}
                    </div>
                  </th>
                  <th colspan="2">
                    <button
                      class="batch100-form-btn dropdown-toggle"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      {this.state.studio_text}
                    </button>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <option
                        value="0"
                        class="dropdown-item"
                        onClick={() => this.select_studio2(0, "Select Studio")}
                        role="button"
                      >
                        All
                      </option>
                      {this.state.studio_available.map((item, i) => (
                        <option
                          value="{item.id}"
                          class="dropdown-item"
                          onClick={() =>
                            this.select_studio2(item.id, item.studio_name)
                          }
                          role="button"
                        >
                          {item.studio_name}
                        </option>
                      ))}
                    </div>
                  </th>
                  <th>
                    <button
                      class="login100-form-btn"
                      type="submit"
                      onClick={this.onSearchHandler}
                    >
                      Search
                    </button>
                  </th>
                </thead>
                <thead>
                  <th colspan="2">Date</th>
                  <th colspan="2">Time</th>
                  <th colspan="2">Group</th>
                  <th>Subject</th>
                  <th colspan="2">Teacher</th>
                  <th colspan="2">Studio</th>
                  <th>Class Status</th>
                  <th>Action</th>
                </thead>
                <thead>
                  <th colSpan="4">
                    <input
                      type="radio"
                      name="order_by"
                      onClick={() => this.select_order("start_time", "")}
                    />{" "}
                    Ascending &nbsp;{" "}
                    <input
                      type="radio"
                      name="order_by"
                      onClick={() => this.select_order("start_time", "-")}
                    />{" "}
                    Descending
                  </th>
                </thead>
                <tbody>
                  {this.state.json_list.map((data, key) => {
                    return (
                      <tr>
                        <td colspan="2">
                          {Moment(data.start_time).format("DD/MM/YYYY")}
                        </td>
                        <td colspan="2">
                          {Moment(data.start_time).format("HH:mm")}-
                          {Moment(data.end_time).format("HH:mm")}
                        </td>
                        <td colspan="2">{data.group_name}</td>
                        <td>{data.subject_name}</td>
                        <td colspan="2">{data.teacher_name}</td>
                        <td colspan="2">{data.studio_name}</td>
                        <td>{data.is_schedule}</td>
                        <td>
                          <button disabled={this.state.isLoading} onClick={() => this.schedule_time(data.id)} >
                            {/* <a
                              href="javascript:void(0)"
                              onClick={() => this.schedule_time(data.id)}
                            > */}
                              Schedule
                            {/* </a> */}
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UnscheduleClass;
